var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "lesson-list-add-to-list vmt-component" }, [
    _c("div", [_vm._v("Add this resource to a list")]),
    _vm._v(" "),
    _c(
      "form",
      {
        attrs: { method: "post" },
        on: {
          submit: function($event) {
            $event.preventDefault()
            return _vm.addToList.apply(null, arguments)
          }
        }
      },
      [
        _c("div", { staticClass: "select-list" }, [
          _c(
            "select",
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.selected,
                  expression: "selected"
                }
              ],
              on: {
                change: function($event) {
                  var $$selectedVal = Array.prototype.filter
                    .call($event.target.options, function(o) {
                      return o.selected
                    })
                    .map(function(o) {
                      var val = "_value" in o ? o._value : o.value
                      return val
                    })
                  _vm.selected = $event.target.multiple
                    ? $$selectedVal
                    : $$selectedVal[0]
                }
              }
            },
            [
              _c("option", { attrs: { value: "", selected: "" } }, [
                _vm._v("Choose list…")
              ]),
              _vm._v(" "),
              _vm._l(_vm.lists, function(list) {
                return _c(
                  "option",
                  { key: list.value, domProps: { value: list.value } },
                  [_vm._v(_vm._s(list.label))]
                )
              }),
              _vm._v(" "),
              _c("option", { attrs: { value: "create_list" } }, [
                _vm._v("Create a list…")
              ])
            ],
            2
          )
        ]),
        _vm._v(" "),
        _vm.submitted
          ? _c("div", [
              _vm._v("Added to list.  "),
              _c("a", { attrs: { href: _vm.listsUrl, target: "_blank" } }, [
                _vm._v("View your lists here")
              ])
            ])
          : _vm._e(),
        _vm._v(" "),
        _c("button", { ref: "button", attrs: { type: "submit" } }, [
          _vm._v("Add to list")
        ]),
        _vm._v(" "),
        _c("lesson-list-create-list", {
          attrs: { open: _vm.selected === "create_list" },
          on: { created: _vm.appendAndAddCreatedList }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }