<template>
    <div class="lesson-list-add-to-list vmt-component">
        <div>Add this resource to a list</div>
        <form method="post" @submit.prevent="addToList">
            <div class="select-list">
                <select v-model="selected">
                    <option value="" selected>Choose list&hellip;</option>
                    <option v-for="list in lists" :key="list.value" :value="list.value">{{ list.label }}</option>
                    <option value="create_list">Create a list&hellip;</option>
                </select>
            </div>
            <div v-if="submitted">Added to list. &nbsp;<a :href="listsUrl" target="_blank">View your lists here</a></div>
            <button type="submit" ref="button">Add to list</button>
            <lesson-list-create-list @created="appendAndAddCreatedList" :open="selected === 'create_list'"></lesson-list-create-list>
        </form>
    </div>
</template>

<script>
export default {
    name: 'LessonListAddToList',
    props: ['postId', 'listsUrl'],

    data() {
        return {
            submitted: false,
            selected: '',
            lists: this.json.lists
        }
    },

    methods: {
        addToList() {
            const params = {
                id: this.selected,
                items: [this.postId],
            }
            jQuery.ajax(window.vmt.apiUrl + 'lesson-lists/items', {
                method: 'PATCH',
                data: params
            }).done((data) => {
                this.submitted = data.result;
                this.$refs.button.blur();
            });
        },
        appendAndAddCreatedList(data) {
            this.lists.push({value: data.id, label: data.title});
            this.selected = data.id;
            this.addToList();
        }
    }
}
</script>

<style scoped lang="scss">
.lesson-list-add-to-list {
    margin-bottom: 1rem;
}
.select-list {
    font-size: 16px;
    margin-bottom: 0.5rem;

    select {
        width: auto;
    }
}
button {
    margin-top: 0.5rem;
}
</style>
