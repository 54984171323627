var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "lesson-list-private-copy-list" },
    [
      _c(
        "action-button",
        _vm._b(
          {
            nativeOn: {
              click: function($event) {
                return _vm.copyList.apply(null, arguments)
              }
            }
          },
          "action-button",
          _vm.buttonAttrs,
          false
        ),
        [_vm._v("Make a Private Copy")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }