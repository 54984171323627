var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "lesson-list-edit-list" },
    [
      _c(
        "action-button",
        _vm._b(
          {
            nativeOn: {
              click: function($event) {
                _vm.showConfirmation = true
              }
            }
          },
          "action-button",
          _vm.buttonAttrs,
          false
        ),
        [_vm._v("Delete List")]
      ),
      _vm._v(" "),
      _c(
        "confirm-dialog",
        {
          on: { confirm: _vm.deleteList },
          model: {
            value: _vm.showConfirmation,
            callback: function($$v) {
              _vm.showConfirmation = $$v
            },
            expression: "showConfirmation"
          }
        },
        [_vm._v("Are you sure you want to delete this Lesson List?")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }