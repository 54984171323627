<template>
    <div class="lesson-list-edit-list">
        <action-button @click.native="openModal=true" v-bind="buttonAttrs">Edit List</action-button>
    <modal :open.sync="openModal" confirm-close :title="`Edit List: ${list.title}`">
        <form @submit.prevent="saveList">
            <div class="fields">
                <div v-if="!isGlobal">
                <label class="title">Title* <input type="text" required v-model="title" placeholder="(name your list)"></label>
                <div>
                    <label><input type="radio" value="public" v-model="visibility"> <strong>Public</strong>. Other members will be able to see it and make a copy.</label>
                    <label><input type="radio" value="private" v-model="visibility"> <strong>Private</strong>. Only you will be able to see what's on it.</label>
                </div>
                <label class="description">Description<template v-if="public">*</template>
                    <textarea v-model="description" :required="public"
                              :placeholder="public ? '(describe your list so others can make use of it)' : '(optional)'">
                    </textarea>
                </label>
                </div>
            </div>
            <span>Re-order or delete items</span>
            <div class="items-wrapper">
                <draggable ref="listItems" :list="items">
                    <transition-group tag="ul" name="vmt-fade" class="table-list items">
                        <li v-for="(item, index) in items" :key="item.title">
                            <span>{{ item.title }}</span>
                            <button type="button" class="button-icon" @click="removeItem(index)"><fa-icon name="remove">Remove</fa-icon></button>
                        </li>
                    </transition-group>
                </draggable>
            </div>
            <button type="submit" class="button-rounded button-small" :disabled="!valid">Update</button>
        </form>
    </modal>
    </div>
</template>

<script>

    export default {
        name: "LessonListEditList",
        props: {
            redirectUrl: String
        },
        data() {
            const list = this.json.list;
            return {
                openModal: false,
                list: list,
                isGlobal: !(list.id && !list.name),
                title: list.title,
                visibility: list.public ? 'public' : 'private',
                description: list.description,
                items: list.items,
                buttonAttrs: {
                    color: 'var(--vmt-color-secondary)',
                    icon: '\\f040'
                }
            }
        },
        watch: {
            immediate: true,
            open(value) {
                this.openModal = value;
            }
        },
        computed: {
            public() {
                return this.visibility === 'public';
            },
            valid() {
                return this.isGlobal || (this.title && !(this.public && !this.description));
            }
        },
        methods: {
            removeItem(index) {
                this.items.splice(index, 1);
            },
            saveList() {
                const params = {
                    id: this.list.id,
                    name: this.list.name,
                    title: this.title,
                    public: this.visibility === 'public' ? 1: 0,
                    description: this.description,
                    items: this.items.map(i => i.id),
                };
                jQuery.ajax(window.vmt.apiUrl + 'lesson-lists/update', {
                    method: 'POST',
                    data: params
                }).done((data) => {
                    this.$emit('updated', data);
                    window.location.href = this.redirectUrl + '?updated=1';
                });
            }
        }
    }
</script>

<style scoped lang="scss">
    .fields {
        display: flex;
        flex-flow: row wrap;
    }
    label {
        flex: 1 1 100%;
        display: block;
        margin-bottom: 1rem;
    }
.table-list {
    li {
        cursor: grab;

        &[draggable=true] {
            opacity: 0.25;
        }
    }
}
.items-wrapper {
    position: relative;
    background-color: #ffffff;
}
.items {
    position: relative;
    min-height: 11.6875rem;
    transition: background-color 0.25s;
    &:empty {
        background-color: rgba(255,255,255, 0);
    }
    li {
        display: flex;
        align-items: baseline;
        justify-content: space-between;
        flex-wrap: nowrap;
    }
}

</style>
