var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "modal",
    {
      attrs: {
        open: _vm.openModal,
        "confirm-close": "",
        title: "Create a New List"
      },
      on: {
        "update:open": function($event) {
          _vm.openModal = $event
        }
      }
    },
    [
      _c(
        "form",
        {
          on: {
            submit: function($event) {
              $event.preventDefault()
              return _vm.saveList.apply(null, arguments)
            }
          }
        },
        [
          _c("div", { staticClass: "fields" }, [
            _c("label", { staticClass: "title" }, [
              _vm._v("Title* "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.title,
                    expression: "title"
                  }
                ],
                attrs: {
                  type: "text",
                  required: "",
                  placeholder: "(name your list)"
                },
                domProps: { value: _vm.title },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.title = $event.target.value
                  }
                }
              })
            ]),
            _vm._v(" "),
            _c("div", [
              _c("label", [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.visibility,
                      expression: "visibility"
                    }
                  ],
                  attrs: { type: "radio", value: "private" },
                  domProps: { checked: _vm._q(_vm.visibility, "private") },
                  on: {
                    change: function($event) {
                      _vm.visibility = "private"
                    }
                  }
                }),
                _vm._v(" "),
                _c("strong", [_vm._v("Private")]),
                _vm._v(
                  ". Only you will be able to see what's on it. Choose this option if this list will only be useful to you."
                )
              ]),
              _vm._v(" "),
              _c("label", [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.visibility,
                      expression: "visibility"
                    }
                  ],
                  attrs: { type: "radio", value: "public" },
                  domProps: { checked: _vm._q(_vm.visibility, "public") },
                  on: {
                    change: function($event) {
                      _vm.visibility = "public"
                    }
                  }
                }),
                _vm._v(" "),
                _c("strong", [_vm._v("Public")]),
                _vm._v(
                  ". Other members will be able to see it and make a copy. Only choose this option if you think this list will be useful for others."
                )
              ])
            ]),
            _vm._v(" "),
            _c(
              "label",
              { staticClass: "description" },
              [
                _vm._v("Description"),
                _vm.public ? [_vm._v("*")] : _vm._e(),
                _vm._v(" "),
                _c("textarea", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.description,
                      expression: "description"
                    }
                  ],
                  attrs: {
                    required: _vm.public,
                    rows: "4",
                    placeholder: _vm.public
                      ? "(describe your list so others can make use of it)"
                      : "(optional)"
                  },
                  domProps: { value: _vm.description },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.description = $event.target.value
                    }
                  }
                })
              ],
              2
            )
          ]),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "button-rounded button-small",
              attrs: { type: "submit", disabled: !_vm.valid }
            },
            [_vm._v("Save")]
          )
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }