<template>
    <modal :open.sync="openModal" confirm-close title="Create a New List">
        <form @submit.prevent="saveList">
            <div class="fields">
                <label class="title">Title* <input type="text" required v-model="title" placeholder="(name your list)"></label>
                <div>
                    <label><input type="radio" value="private" v-model="visibility"> <strong>Private</strong>. Only you will be able to see what's on it. Choose this option if this list will only be useful to you.</label>
                    <label><input type="radio" value="public" v-model="visibility"> <strong>Public</strong>. Other members will be able to see it and make a copy. Only choose this option if you think this list will be useful for others.</label>
                </div>
                <label class="description">Description<template v-if="public">*</template>
                    <textarea v-model="description" :required="public" rows="4"
                              :placeholder="public ? '(describe your list so others can make use of it)' : '(optional)'">
                    </textarea>
                </label>
            </div>
            <button type="submit" class="button-rounded button-small" :disabled="!valid">Save</button>
        </form>
    </modal>
</template>

<script>

    export default {
        name: "LessonListCreateList",
        props: {
            open: Boolean
        },
        data() {
            return {
                openModal: false,
                title: '',
                visibility: 'private',
                description: ''
            }
        },
        watch: {
            immediate: true,
            open(value) {
                this.openModal = value;
            }
        },
        computed: {
            public() {
                return this.visibility === 'public';
            },
            valid() {
                return this.title && !(this.public && !this.description);
            }
        },
        methods: {
            saveList() {
                const params = {
                    title: this.title,
                    public: this.public ? 1: 0,
                    description: this.description
                };
                jQuery.ajax(window.vmt.apiUrl + 'lesson-lists/create', {
                    method: 'POST',
                    data: params
                }).done((data) => {
                    this.$emit('created', data);
                    this.modalOpen = false;
                    this.title = this.description = '';
                });
            }
        }
    }
</script>

<style scoped lang="scss">
    .fields {
        display: flex;
        flex-flow: row wrap;
    }
    label {
        flex: 1 1 100%;
        display: block;
        margin-bottom: 1rem;
    }
</style>
