var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "lesson-list-global-toggle", class: "toggle-" + _vm.name },
    [
      _c(
        "action-button",
        _vm._b(
          {
            ref: "button",
            attrs: { active: _vm.isActive },
            nativeOn: {
              click: function($event) {
                return _vm.toggle.apply(null, arguments)
              }
            }
          },
          "action-button",
          _vm.buttonAttrs,
          false
        ),
        [_vm._v(_vm._s(_vm.isActive ? _vm.buttonActiveText : _vm.buttonText))]
      ),
      _vm._v(" "),
      _c("span", { staticClass: "toggle-text" }, [_vm._t("default")], 2)
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }