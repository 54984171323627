<template>
    <div class="lesson-list-private-copy-list">
        <action-button @click.native="copyList" v-bind="buttonAttrs">Make a Private Copy</action-button>
    </div>
</template>

<script>

    export default {
        name: "LessonListPrivateCopyList",
        props: {
            id: String
        },
        data() {
            return {
                buttonAttrs: {
                    color: 'var(--vmt-color-secondary-light)',
                    icon: '\\f055'
                }
            }
        },
        computed: {
        },
        methods: {
            copyList() {
                const params = {
                    id: this.id,
                };
                jQuery.ajax(window.vmt.apiUrl + 'lesson-lists/private-copy', {
                    method: 'POST',
                    data: params
                }).done((data) => {
                    this.$emit('private-copied', data);
                    window.location.href = data.url + '?copied=' + this.id;
                });
            }
        }
    }
</script>

<style scoped lang="scss">
    .fields {
        display: flex;
        flex-flow: row wrap;
    }
    label {
        flex: 1 1 100%;
        display: block;
        margin-bottom: 1rem;
    }
.table-list {
    li {
        cursor: grab;

        &[draggable=true] {
            opacity: 0.25;
        }
    }
}
.items-wrapper {
    position: relative;
    background-color: #ffffff;
}
.items {
    position: relative;
    min-height: 11.6875rem;
    transition: background-color 0.25s;
    &:empty {
        background-color: rgba(255,255,255, 0);
    }
    li {
        display: flex;
        align-items: baseline;
        justify-content: space-between;
        flex-wrap: nowrap;
    }
}

</style>
