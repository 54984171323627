<template>
    <div class="lesson-list-edit-list">
        <action-button @click.native="showConfirmation = true" v-bind="buttonAttrs">Delete List</action-button>
        <confirm-dialog v-model="showConfirmation" @confirm="deleteList">Are you sure you want to delete this Lesson List?</confirm-dialog>
    </div>
</template>

<script>

    export default {
        name: "LessonListDeleteList",
        props: {
            id: String,
            redirectUrl: String
        },
        data() {
            return {
                showConfirmation: false,
                buttonAttrs: {
                    color: 'var(--vmt-color-primary)',
                    icon: '\\f014'
                }
            }
        },
        computed: {
        },
        methods: {
            deleteList() {
                const params = {
                    id: this.id
                };
                jQuery.ajax(window.vmt.apiUrl + 'lesson-lists/delete', {
                    method: 'POST',
                    data: params
                }).done((data) => {
                    this.$emit('deleted', data);
                    window.location.href = this.redirectUrl + '?deleted=' + this.id;
                });
            }
        }
    }
</script>

<style scoped lang="scss">
    .fields {
        display: flex;
        flex-flow: row wrap;
    }
    label {
        flex: 1 1 100%;
        display: block;
        margin-bottom: 1rem;
    }
.table-list {
    li {
        cursor: grab;

        &[draggable=true] {
            opacity: 0.25;
        }
    }
}
.items-wrapper {
    position: relative;
    background-color: #ffffff;
}
.items {
    position: relative;
    min-height: 11.6875rem;
    transition: background-color 0.25s;
    &:empty {
        background-color: rgba(255,255,255, 0);
    }
    li {
        display: flex;
        align-items: baseline;
        justify-content: space-between;
        flex-wrap: nowrap;
    }
}

</style>
