// Vue instances
import LessonListGlobalToggle from "./components/LessonListGlobalToggle.vue";
import LessonListAddToList from "./components/LessonListAddToList.vue";
import LessonListCreateList from './components/LessonListCreateList.vue';
import LessonListEditList from './components/LessonListEditList.vue';
import LessonListPrivateCopyList from './components/LessonListPrivateCopyList.vue';
import LessonListDeleteList from './components/LessonListDeleteList.vue';

(function() {
    const Vue = window.Vue || false;
    if (!Vue) {
        return;
    }
    Vue.component('lesson-list-global-toggle', LessonListGlobalToggle);
    Vue.component('lesson-list-add-to-list', LessonListAddToList);
    Vue.component('lesson-list-create-list', LessonListCreateList);
    Vue.component('lesson-list-edit-list', LessonListEditList);
    Vue.component('lesson-list-private-copy-list', LessonListPrivateCopyList);
    Vue.component('lesson-list-delete-list', LessonListDeleteList);
})();
