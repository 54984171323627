<template>
<div class="lesson-list-global-toggle" :class="`toggle-${name}`">
    <action-button ref="button" :active="isActive" @click.native="toggle"
        v-bind="buttonAttrs"
    >{{ isActive ? buttonActiveText : buttonText }}</action-button>
    <span class="toggle-text"><slot></slot></span>
</div>
</template>

<script>
const buttonAttrs = {
    favourites: {
        color: 'var(--vmt-color-primary)',
        icon: "\\f004",
        iconSize: '15px'
    },
    tried: {
        color: 'var(--vmt-color-secondary)',
        icon: "\\f005",
        iconSize: '17px'
    }
}
export default {
    name: 'LessonListGlobalToggle',
    props: {
        name: {
            type: String,
        },
        buttonText: {
            type: String,
        },
        buttonActiveText: {
            type: String
        },
        active: {
            type: Boolean,
            default: false
        },
        postId: {
            type: String,
            default: '0'
        }
    },
    data() {
        return {
            buttonAttrs: buttonAttrs[this.name],
            isActive: this.active
        }
    },
    computed: {
        buttonClass() {
            return ['toggle-button', `toggle-${this.name}-button`, this.isActive ? 'active' : false];
        }
    },
    methods: {
        toggle() {
            this.isActive = !this.isActive;
            const params = {
                name: this.name,
                post_id: this.postId,
                toggle: Number(this.isActive)
            }
            jQuery.post(window.vmt.apiUrl + 'lesson-lists/global/toggle', params)
                .done((data) => {
                    this.isActive = data.toggle;
                });
        }
    }
}
</script>

<style scoped lang="scss">
    .lesson-list-global-toggle {
        width: 100%;
        display: inline-block;

        .vmt-action-button {
            float: left;
            min-width: 200px;
        }

        .toggle-text {
            font-size: 14px;
            line-height: 36px;
            margin-left: 2%;
            float: left;
            font-weight: 600;
            font-style: italic;
        }
    }
</style>
