var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "lesson-list-edit-list" },
    [
      _c(
        "action-button",
        _vm._b(
          {
            nativeOn: {
              click: function($event) {
                _vm.openModal = true
              }
            }
          },
          "action-button",
          _vm.buttonAttrs,
          false
        ),
        [_vm._v("Edit List")]
      ),
      _vm._v(" "),
      _c(
        "modal",
        {
          attrs: {
            open: _vm.openModal,
            "confirm-close": "",
            title: "Edit List: " + _vm.list.title
          },
          on: {
            "update:open": function($event) {
              _vm.openModal = $event
            }
          }
        },
        [
          _c(
            "form",
            {
              on: {
                submit: function($event) {
                  $event.preventDefault()
                  return _vm.saveList.apply(null, arguments)
                }
              }
            },
            [
              _c("div", { staticClass: "fields" }, [
                !_vm.isGlobal
                  ? _c("div", [
                      _c("label", { staticClass: "title" }, [
                        _vm._v("Title* "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.title,
                              expression: "title"
                            }
                          ],
                          attrs: {
                            type: "text",
                            required: "",
                            placeholder: "(name your list)"
                          },
                          domProps: { value: _vm.title },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.title = $event.target.value
                            }
                          }
                        })
                      ]),
                      _vm._v(" "),
                      _c("div", [
                        _c("label", [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.visibility,
                                expression: "visibility"
                              }
                            ],
                            attrs: { type: "radio", value: "public" },
                            domProps: {
                              checked: _vm._q(_vm.visibility, "public")
                            },
                            on: {
                              change: function($event) {
                                _vm.visibility = "public"
                              }
                            }
                          }),
                          _vm._v(" "),
                          _c("strong", [_vm._v("Public")]),
                          _vm._v(
                            ". Other members will be able to see it and make a copy."
                          )
                        ]),
                        _vm._v(" "),
                        _c("label", [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.visibility,
                                expression: "visibility"
                              }
                            ],
                            attrs: { type: "radio", value: "private" },
                            domProps: {
                              checked: _vm._q(_vm.visibility, "private")
                            },
                            on: {
                              change: function($event) {
                                _vm.visibility = "private"
                              }
                            }
                          }),
                          _vm._v(" "),
                          _c("strong", [_vm._v("Private")]),
                          _vm._v(". Only you will be able to see what's on it.")
                        ])
                      ]),
                      _vm._v(" "),
                      _c(
                        "label",
                        { staticClass: "description" },
                        [
                          _vm._v("Description"),
                          _vm.public ? [_vm._v("*")] : _vm._e(),
                          _vm._v(" "),
                          _c("textarea", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.description,
                                expression: "description"
                              }
                            ],
                            attrs: {
                              required: _vm.public,
                              placeholder: _vm.public
                                ? "(describe your list so others can make use of it)"
                                : "(optional)"
                            },
                            domProps: { value: _vm.description },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.description = $event.target.value
                              }
                            }
                          })
                        ],
                        2
                      )
                    ])
                  : _vm._e()
              ]),
              _vm._v(" "),
              _c("span", [_vm._v("Re-order or delete items")]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "items-wrapper" },
                [
                  _c(
                    "draggable",
                    { ref: "listItems", attrs: { list: _vm.items } },
                    [
                      _c(
                        "transition-group",
                        {
                          staticClass: "table-list items",
                          attrs: { tag: "ul", name: "vmt-fade" }
                        },
                        _vm._l(_vm.items, function(item, index) {
                          return _c("li", { key: item.title }, [
                            _c("span", [_vm._v(_vm._s(item.title))]),
                            _vm._v(" "),
                            _c(
                              "button",
                              {
                                staticClass: "button-icon",
                                attrs: { type: "button" },
                                on: {
                                  click: function($event) {
                                    return _vm.removeItem(index)
                                  }
                                }
                              },
                              [
                                _c("fa-icon", { attrs: { name: "remove" } }, [
                                  _vm._v("Remove")
                                ])
                              ],
                              1
                            )
                          ])
                        }),
                        0
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "button-rounded button-small",
                  attrs: { type: "submit", disabled: !_vm.valid }
                },
                [_vm._v("Update")]
              )
            ]
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }